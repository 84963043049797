import React from 'react'
import SelectOptions from '../components/Test/SelectOptions'

const TestSeletOpt = () => {
  return (
    <div>
        <SelectOptions/>
    </div>
  )
}

export default TestSeletOpt