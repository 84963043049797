import React from 'react'
import CheckboxGroup from '../../features/checkBox/CheckboxGroup'

const Notification = () => {
  return (
    <div>
        <CheckboxGroup/>
    </div>
  )
}

export default Notification